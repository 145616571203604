<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form class="custom-form">
                    <v-card :elevation="1" color="indigo lighten-4">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-card-text class="py-0">
                                    <v-row dense>
                                        <v-col cols="3">
                                            <v-row no-gutters>
                                                <v-col cols="3">Type</v-col>
                                                <v-col cols="9">
                                                    <v-combobox 
                                                        v-model="searchType"
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :items="['All', 'Doctor']"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="3" v-if="searchType == 'Doctor'">
                                            <v-row no-gutters>
                                                <v-col cols="3">Doctor</v-col>
                                                <v-col cols="9">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :items="['D0001 - Jakirul Islam', 'D0002 - Shadhin']"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="5">
                                            <v-row no-gutters>
                                                <v-col cols="2">Form Date</v-col>
                                                <v-col cols="4">
                                                    <v-menu>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="date"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                            <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="date"></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="2" class="text-center">To Date</v-col>
                                                <v-col cols="4">
                                                    <v-menu>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="date"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                            <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="date"></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="1">
                                            <v-btn height="26px" dark block color="light-blue darken-2">Search</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <v-btn small color="blue-grey lighten-4">Print</v-btn>
            </v-col>
            <v-col cols="12">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Patient Name</th>
                                <th>Mobile</th>
                                <th>Doctor Name</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(list, ind) in presentList" :key="ind">
                                <td>{{ ind + 1 }}</td>
                                <td>{{ list.pctName }}</td>
                                <td>{{ list.mobile }}</td>
                                <td>{{ list.dctName }}</td>
                                <td>{{ list.date }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    name: 'PresentEntry',
    data: ()=> ({
        date: new Date().toISOString().substr(0, 10),
        searchType: null,

        presentList: [
            {
                pctName: 'Md Jakirul Islam',
                mobile: '01796953487',
                dctName: 'Mr X',
                date: '08-11-2020'  
            },
            {
                pctName: 'Md Jakirul Islam',
                mobile: '01796953487',
                dctName: 'Mr X',
                date: '08-11-2020'  
            },
            {
                pctName: 'Md Jakirul Islam',
                mobile: '01796953487',
                dctName: 'Mr X',
                date: '08-11-2020'  
            },
            {
                pctName: 'Md Jakirul Islam',
                mobile: '01796953487',
                dctName: 'Mr X',
                date: '08-11-2020'  
            },
            {
                pctName: 'Md Jakirul Islam',
                mobile: '01796953487',
                dctName: 'Mr X',
                date: '08-11-2020'  
            },
            {
                pctName: 'Md Jakirul Islam',
                mobile: '01796953487',
                dctName: 'Mr X',
                date: '08-11-2020'  
            },
            {
                pctName: 'Md Jakirul Islam',
                mobile: '01796953487',
                dctName: 'Mr X',
                date: '08-11-2020'  
            },
            {
                pctName: 'Md Jakirul Islam',
                mobile: '01796953487',
                dctName: 'Mr X',
                date: '08-11-2020'  
            },
            {
                pctName: 'Md Jakirul Islam',
                mobile: '01796953487',
                dctName: 'Mr X',
                date: '08-11-2020'  
            },
            {
                pctName: 'Md Jakirul Islam',
                mobile: '01796953487',
                dctName: 'Mr X',
                date: '08-11-2020'  
            },
            {
                pctName: 'Md Jakirul Islam',
                mobile: '01796953487',
                dctName: 'Mr X',
                date: '08-11-2020'  
            },
            {
                pctName: 'Md Jakirul Islam',
                mobile: '01796953487',
                dctName: 'Mr X',
                date: '08-11-2020'  
            }
        ]
    }),
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
    }
    .v-icon.v-icon[data-v-1f38b4e5] {
        font-size: 14px !important;
        top: 0px !important; 
    }
</style>